import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  token: '',
  user: {},
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAuth: (state, action) => ({ ...state, ...action.payload }),
    logout: () => initialState,
  },
});

export const { setAuth, logout } = authSlice.actions;

export const getAuth = (state) => state.auth;
export const getToken = (state) => state.auth.token;

export default authSlice.reducer;
