import './index.css';

import React, { lazy, Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { persistStore } from 'reduxjs-toolkit-persist';
import { PersistGate } from 'reduxjs-toolkit-persist/integration/react';

import { store } from './store';

const App = lazy(() => import('./App'));

const persistor = persistStore(store);

ReactDOM.render(
  <React.StrictMode>
    <Suspense fallback={<p>Loading</p>}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <App />
        </PersistGate>
      </Provider>
    </Suspense>
  </React.StrictMode>,
  document.getElementById('root'),
);
